<template>
  <div>
    <div v-if="loading" class="text-center">
      <v-progress-circular indeterminate color="primary" />
    </div>

    <v-slide-group v-else-if="data.length" multiple show-arrows class="slide-group">
      <template #prev>
        <v-btn icon class="slide-group-prev" @click="$refs.slideGroup.prev()">
          <v-icon size="36">mdi-chevron-left</v-icon>
        </v-btn>
      </template>

      <template #next>
        <v-btn icon class="slide-group-next" @click="$refs.slideGroup.next()">
          <v-icon size="36">mdi-chevron-right</v-icon>
        </v-btn>
      </template>
      <v-slide-item v-for="([item, rawItem], i) in data" :key="i + item.id">
        <v-card
          width="172px"
          class="my-4 mr-2 ml-2 d-block elevation-4"
          style="position: relative"
          v-on="wrapHover(item, null)"
        >
          <v-menu offset-y :nudge-width="-32" :nudge-height="-32" :close-on-content-click="false" z-index="100">
            <template #activator="{ on: menuOn, attrs: menuAttr }">
              <v-btn
                v-show="hoveredItem === item"
                style="position: fixed; z-index: 100; margin-left: 115px; margin-top: 10px"
                v-bind="menuAttr"
                fab
                small
                v-on="menuOn"
              >
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="editQuickAccessItem(rawItem)">
                <v-icon left size="18">mdi-file-document</v-icon>
                <v-list-item-content>
                  <v-list-item-title>メモを編集</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="removeQuickAccessItem(rawItem)">
                <v-icon left size="18">mdi-delete</v-icon>
                <v-list-item-content>
                  <v-list-item-title>削除</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <nuxt-link
            :to="`${item.url}`"
            :target="'_blank'"
            class="text-decoration-none d-inline-block d-flex flex-column align-center"
            @click.native="quickAccessBookmarkClickHandler"
          >
            <div class="mt-4 d-flex flex-column align-center thumbnail-wrapper">
              <div v-if="item.thumbnailURI">
                <img
                  v-if="shouldAppendNewIcon(rawItem)"
                  src="@/assets/new_icon.svg"
                  alt="NewDocument"
                  class="new-icon"
                />
                <v-img class="thumbnail" height="160px" width="113px" :src="item.thumbnailURI" cover />
              </div>
              <div v-else class="alt-thumbnail">
                <p class="doc-title">{{ abbreviateDocTitle(item.title) }}</p>
              </div>
              <v-tooltip top :disabled="isDisableHeadingTextBounded(item.headingText)">
                <template #activator="{ on: authorOn, attrs: authorAttrs }">
                  <v-card-title
                    v-bind="authorAttrs"
                    class="card-title font-weight-bold text-primary pb-0"
                    v-on="authorOn"
                  >
                    <div>
                      {{ shortenDocHeadingText(item.headingText) || '&nbsp;' }}
                    </div>
                  </v-card-title>
                </template>
                <span>
                  {{ item.headingText || '&nbsp;' }}
                </span>
              </v-tooltip>
            </div>

            <!-- 著者名 -->
            <v-card-text class="d-flex flex-column" style="font-size: 13px; line-height: 15px">
              <div class="text-item">
                <v-icon left small>mdi-account</v-icon>
                <v-tooltip top>
                  <template #activator="{ on: authorOn, attrs: authorAttrs }">
                    <span v-bind="authorAttrs" v-on="authorOn">
                      {{ authorName(item) }}
                    </span>
                  </template>
                  <span>
                    {{ authorName(item) }}
                  </span>
                </v-tooltip>
              </div>

              <!-- 掲載元 -->
              <div class="text-item">
                <v-icon left small>mdi-domain</v-icon>
                <span>{{ item.publisher }}</span>
              </div>

              <!-- 公開日 -->
              <div class="text-item">
                <v-icon left small>mdi-calendar-blank</v-icon>
                <span>{{ item.publishedOn }}</span>
              </div>

              <!-- 更新日 -->
              <p class="text-item text-caption mb-0" style="line-height: 14px">{{ item.updatedAt }}更新</p>
            </v-card-text>
          </nuxt-link>
        </v-card>
      </v-slide-item>
    </v-slide-group>

    <p v-else class="text-body-2 text--secondary d-flex align-center" style="color: rgba(0, 0, 0, 0.56)">
      何度も閲覧する文書をクイックアクセスに登録すると、こちらから簡単にアクセスできるようになります
    </p>

    <delete-quick-access-item-dialog ref="deleteQuickAccessItemDialog" :success="updateQuickAccessItems" />
    <modify-quick-access-item-dialog ref="modifyQuickAccessItemDialog" :success="updateQuickAccessItems" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'nuxt-property-decorator';
import {
  QuickAccessItemTypeDocumentPropertiesViewTypeEnum,
  QuickAccessItemTypePdfPropertiesViewTypeEnum,
  QuickAccessItemTypeWebPropertiesViewTypeEnum,
  QuickAccessItemWithBibliography,
} from 'wklr-backend-sdk/models';
import DeleteQuickAccessItemDialog from '@/components/quickAccess/delete-item.vue';
import ModifyQuickAccessItemDialog from '@/components/quickAccess/modify-item.vue';
import NO_IMAGE from '@/assets/noimage.png';
import { shouldAppendNewIcon } from '../utils/documentUtils';

interface QuickAccessItem {
  id: string;
  description: string;
  url: string;
  thumbnailURI: string | null;
  title: string;
  headingText: string;
  authors: string[];
  publisher: string;
  publishedOn: string;
  updatedAt: string;
}

@Component({
  components: {
    ModifyQuickAccessItemDialog,
    DeleteQuickAccessItemDialog,
  },
})
export default class QuickAccess extends Vue {
  $refs!: {
    modifyQuickAccessItemDialog: ModifyQuickAccessItemDialog;
    deleteQuickAccessItemDialog: DeleteQuickAccessItemDialog;
    slideGroup: Vue & { prev: () => void; next: () => void };
  };

  readonly noImage = NO_IMAGE;
  readonly maxHeadingChars = 15;

  loading = true;
  data: [QuickAccessItem, QuickAccessItemWithBibliography][] = [];
  hoveredItem: QuickAccessItem | null = null;

  async mounted(): Promise<void> {
    this.loading = true;
    await this.updateQuickAccessItems();
    this.loading = false;
  }

  wrapHover(
    item: QuickAccessItem,
    on: { [eventName: string]: (e: Event) => void } | null,
  ): { [eventName: string]: (e: Event) => void } {
    return {
      ...on,
      mouseenter: (e: Event) => {
        this.hoveredItem = item;
        if (on?.mouseenter) {
          return on.mouseenter(e);
        }
      },
      mouseleave: (e: Event) => {
        if (this.hoveredItem === item) {
          this.hoveredItem = null;
        }
        if (on?.mouseleave) {
          return on.mouseleave(e);
        }
      },
    };
  }

  async updateQuickAccessItems(): Promise<void> {
    const items = await this.$repositories.quickAccess.getItems();
    this.data = items.map((item) => {
      let url;
      switch (item.viewType) {
        case QuickAccessItemTypeWebPropertiesViewTypeEnum.Web:
          url = `/document/${item.doc.id}#key=${item.key}`;
          break;
        case QuickAccessItemTypePdfPropertiesViewTypeEnum.Pdf:
          url = `/document/${item.doc.id}?view=pdf#page=${item.pageSeq + 1}`;
          break;
        case QuickAccessItemTypeDocumentPropertiesViewTypeEnum.Document:
          url = `/document/${item.doc.id}`;
          break;
        default:
          console.error('未知の viewType です', item);
          url = '';
      }
      return [
        {
          id: item.id,
          description: item.description,
          url,
          thumbnailURI: item.doc.thumbnailURI,
          title: item.doc.title,
          // FIXME: API で headingText に doc.title を詰めて返すようにする
          headingText: 'headingText' in item ? item.headingText : item.doc.title,
          authors: item.doc.authors,
          publisher: item.doc.publisher,
          publishedOn: new Date(item.doc.publishedOn ?? 0).toLocaleDateString('ja-JP', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }),
          updatedAt: new Date(item.updatedAt * 1000).toLocaleDateString('ja-JP', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }),
        },
        item,
      ];
    });
  }

  // 先頭21文字、末尾27文字
  abbreviateDocTitle(str: string): string {
    if (str.length <= 21 + 27) {
      return str;
    }
    return str.slice(0, 21) + '…' + str.slice(str.length - 27);
  }

  isDisableHeadingTextBounded(str: string): boolean {
    return str.length < this.maxHeadingChars;
  }

  shortenDocHeadingText(str: string): string {
    if (this.isDisableHeadingTextBounded(str)) {
      return str;
    }

    return str.slice(0, this.maxHeadingChars) + '⋯';
  }

  editQuickAccessItem(item: QuickAccessItemWithBibliography): void {
    this.$refs.modifyQuickAccessItemDialog.show({ ...item });
  }

  removeQuickAccessItem(item: QuickAccessItemWithBibliography): void {
    this.$refs.deleteQuickAccessItemDialog.show({ ...item });
  }

  quickAccessBookmarkClickHandler(): void {
    this.$telemetry.sendClickTelemetry({ button: 'quick_access__bookmark' }, this.$route);
  }

  authorName(item: QuickAccessItem) {
    return item.authors.length === 0 ? '-' : item.authors.join(',');
  }

  shouldAppendNewIcon(quickAccessItem: QuickAccessItemWithBibliography): boolean {
    return shouldAppendNewIcon(quickAccessItem.doc.collectedAt);
  }
}
</script>

<style lang="scss" scoped>
.card-title {
  display: -webkit-box;
  div {
    line-height: 19px;
    font-size: 16px;
    height: 2lh;
  }
}

.text-item {
  display: flex;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.56);
  span {
    text-decoration: none !important;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.thumbnail-wrapper {
  text-align: center;
  > .thumbnail {
    vertical-align: top;
    max-width: 100%;
    max-height: 100%;
    box-shadow: 0 0 5px #00000020;
  }
  > .alt-thumbnail {
    display: inline-block;
    padding: 20px 12px;
    height: 160px;
    width: 124px;
    background: #e9e9d6;
    > .doc-title {
      height: 100%;
      width: 100%;
      margin: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Roboto;
      font-size: 14px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.87);
    }
  }
}

.slide-group {
  &:has(> .v-slide-group__prev--disabled) .slide-group-prev,
  &:has(> .v-slide-group__next--disabled) .slide-group-next {
    opacity: 0.5;
  }
}

.new-icon {
  position: absolute;
  right: 0;
  padding-right: 25px;
  top: 11px;
  z-index: 100;
}
</style>
